<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-card-title>
        Nuevo registro

        <v-spacer></v-spacer>
      </v-card-title>

      <v-breadcrumbs :items="routes">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card>

    <v-container fluid>
      <div v-if="register_loading">
        <v-subheader>Guardando datos</v-subheader>

        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>

      <v-form v-on:submit.prevent="saveRegister">
        <v-row>
          <v-col cols="12" md="8">
            <v-card>
              <v-card-title>
                Información
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="10">
                    <v-file-input
                      v-model="data.file"
                      :error-messages="DataFileErrors"
                      show-size
                      counter
                      accept="application/pdf"
                      label="Selecciona el PDF"
                      @input="$v.data.file.$touch()"
                      @blur="$v.data.file.$touch()"
                    ></v-file-input>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-btn
                      :disabled="data.file == null"
                      block
                      color="warning"
                      @click="fileChanged"
                    >
                      <v-icon small>mdi-file-pdf</v-icon>
                      PDF
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="signatories_error != null">
                  <v-col>
                    <v-alert text outlined color="warning" icon="mdi-fire">
                      {{ signatories_error }}
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row v-if="register_error != null">
                  <v-col>
                    <v-alert text outlined color="deep-orange" icon="mdi-fire">
                      {{ register_error }}
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="register.name"
                      :error-messages="RegisterNameErrors"
                      label="Nombre"
                      prepend-icon="mdi-account"
                      clearable
                      @input="$v.register.name.$touch()"
                      @blur="$v.register.name.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="register.tax_id"
                      label="RFC"
                      prepend-icon="mdi-magnify"
                      clearable
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="register.email"
                      :error-messages="RegisterEmailErrors"
                      label="Correo"
                      prepend-icon="mdi-magnify"
                      clearable
                      @input="$v.register.email.$touch()"
                      @blur="$v.register.email.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-btn-toggle v-model="toggle_exclusive" mandatory dark>
                      <v-btn text @click="resetRegister()">
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                      <v-btn text @click="addRegister()">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="headers_signatories"
                      :items="data.signatories"
                      hide-default-footer
                      class="elevation-1"
                    >
                      <template v-slot:item.acciones="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              dark
                              small
                              color="error"
                              rounded
                              @click="removeRegister(item)"
                            >
                              <v-icon small>mdi-trash-can</v-icon>
                              Borrar
                            </v-btn>
                          </template>
                          <span>Borrar</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:no-data> Sin datos </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <v-card-title>
                Acciones disponibles
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text> Por favor selecciona una opción </v-card-text>

              <v-card-actions>
                <v-btn
                  color="error"
                  @click="closeForm()"
                  :disabled="register_loading"
                >
                  <v-icon>mdi-close</v-icon>
                  Cerrar
                </v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="register_loading"
                >
                  <v-icon>mdi-content-save</v-icon>
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog v-model="dialog" scrollable persistent max-width="1000px">
      <v-card>
        <v-card-title>Impresión digital</v-card-title>

        <v-card-text>
          <pdf :src="file_local_url"></pdf>
          <!--
          <embed
            :src="file_local_url"
            width="100%"
            height="600px"
            type="application/pdf"
          />
          -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="red" @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    data: {
      file: { required },
    },
    register: {
      name: { required },
      email: { required, email },
    },
  },

  name: "RegisterNew",
  components: {
    pdf,
  },
  data: () => ({
    // Navigation
    routes: [
      {
        text: "Registros",
        disabled: false,
        href: "/register/index",
      },
      {
        text: "Nuevo",
        disabled: true,
        href: "/register/new",
      },
    ],
    // Form Data
    dialog: false,
    toggle_exclusive: undefined,
    register: {
      name: null,
      email: null,
      tax_id: null,
    },
    register_error: null,
    register_loading: false,
    file_local_url: null,
    data: {
      file: null,
      signatories: [],
    },
    signatories_error: null,
    headers_signatories: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "RFC", value: "tax_id", sortable: false },
      { text: "Correo", value: "email", sortable: false },
      { text: "Acciones", value: "acciones", sortable: false },
    ],
  }),
  mounted() {
    this.valityRegisters();
  },
  methods: {
    valityRegisters() {
      let role = this.$store.getters.getUser.role;

      if (role != "admin") {
        let count = this.$store.getters.getRegistersAvaibles;

        if (count <= 0) {
          this.closeForm();
        }
      }
    },
    async fileChanged() {
      this.file_local_url = await this.loadPdfAsDataUrl(this.data.file);

      this.dialog = true;
    },
    async loadPdfAsDataUrl(file) {
      const url = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => resolve(e.target.result);
      });
      return url;
    },
    addRegister() {
      this.$v.register.$touch();

      if (!this.$v.register.$invalid) {
        let exist_register = this.data.signatories.indexOf(this.register);

        if (exist_register == -1) {
          let exist_email = this.data.signatories.find(
            (e) => e.email == this.register.email
          );

          if (exist_email == null) {
            this.data.signatories.push({
              name: this.register.name,
              email: this.register.email,
              tax_id: this.register.tax_id,
            });
            this.resetRegister();
          } else {
            this.register_error =
              "El correo ya esta siendo usado en otro registro";
          }
        } else {
          this.register_error = "El contacto ya existe";
        }
      }
    },
    removeRegister(register) {
      let index = this.data.signatories.indexOf(register);

      this.data.signatories.splice(index, 1);
    },
    resetRegister() {
      this.register.name = null;
      this.register.email = null;
      this.register_error = null;
      this.$v.register.$reset();
    },
    async saveRegister() {
      this.$v.data.$touch();

      if (!this.$v.data.$invalid) {
        this.signatories_error = null;

        if (this.data.signatories.length == 0) {
          this.signatories_error =
            "Se necesita al menos un registro para generar la solicitud";
        } else {
          let params = new FormData();

          params.append("file", this.data.file);

          for (let index = 0; index < this.data.signatories.length; index++) {
            params.append(
              "signatories[" + index + "][name]",
              this.data.signatories[index].name
            );
            params.append(
              "signatories[" + index + "][email]",
              this.data.signatories[index].email
            );
          }

          this.register_loading = true;

          this.$axios
            .post("documents/", params)
            .then((response) => {
              this.sendEmail(response.data);
              this.saveLocalRegister(response.data);
            })
            .catch((error) => {
              console.log(error.response.data);
            })
            .finally(() => (this.register_loading = false));
        }
      }
    },
    saveLocalRegister(data) {
      let params = {
        user_id: this.$store.getters.getUser.id,
        document_id: data.id,
      };

      this.$axios
        .post("https://fiel.compratec.com.mx/backend/public/api/file", params)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    sendEmail(data) {
      let params = {
        signers: [],
      };

      data.signers.forEach((e) => {
        let signer = {
          name: e.name,
          email: e.email,
          file_file_name: data.file_file_name,
          widget_id: e.widget_id,
        };

        params.signers.push(signer);
      });

      this.$axios
        .post(
          "https://fiel.compratec.com.mx/backend/public/api/signer/notification",
          params
        )
        .then((response) => {
          this.closeForm();
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    closeForm() {
      this.$router.push("/register/index");
    },
  },
  watch: {
    "register.tax_id"(value) {
      this.register.tax_id = value.toUpperCase();
    },
  },
  computed: {
    DataFileErrors() {
      const errors = [];
      if (!this.$v.data.file.$dirty) return errors;
      !this.$v.data.file.required && errors.push("Archivo es requerido");
      return errors;
    },
    RegisterNameErrors() {
      const errors = [];
      if (!this.$v.register.name.$dirty) return errors;
      !this.$v.register.name.required && errors.push("Nombre es requerido");
      return errors;
    },
    RegisterEmailErrors() {
      const errors = [];
      if (!this.$v.register.email.$dirty) return errors;
      !this.$v.register.email.required && errors.push("Correo es requerido");
      !this.$v.register.email.email &&
        errors.push("Correo electrónico debe de ser valido");
      return errors;
    },
  },
};
</script>

